import { ActionIcon, Autocomplete, Box, Button, Divider, Drawer, Group, Select, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { createReference, normalizeErrorString } from '@medplum/core';
import { Appointment, Patient, Practitioner, Reference, Schedule, Slot } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { IconCircleCheck, IconCircleOff, IconEdit, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Event } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { CreateUpdateSlot } from './CreateUpdateSlot';
import PatientPractitionerSelect from '../../../components/PatientPractitionerSelect';
import { AppointmentTypes } from '../../../utils/constant';
import { bookSlot } from '../../../utils/CustomAPI';

interface CreateAppointmentProps {
  patient?: Patient;
  event: Event | undefined;
  readonly opened: boolean;
  readonly handlers: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  readonly onAppointmentsUpdated: () => void;
  schedule: Schedule;
}

interface AppointmentFormData {
  patientRef: { value: string; label: string } | undefined;
  appointmentType: string;
  serviceType: string;
  comment: string;
}

export function CreateAppointment(props: CreateAppointmentProps): JSX.Element | null {
  const { patient, event, opened, handlers, onAppointmentsUpdated, schedule } = props;
  const slot: Slot | undefined = event?.resource;

  const [updateSlotOpened, updateSlotHandlers] = useDisclosure(false, { onClose: handlers.close });
  const medplum = useMedplum();
  const profile = useMedplumProfile() as Practitioner;
  const navigate = useNavigate();

  // State for form data
  const [formData, setFormData] = useState<AppointmentFormData>({
    patientRef: undefined,
    appointmentType: '',
    serviceType: '',
    comment: '',
  });

  const handleResourceSelect = (resource: { value: string; label: string } | undefined): void => {
    setFormData((prev) => ({ ...prev, patientRef: resource }));
  };

  async function handleDeleteSlot(slotId: string): Promise<void> {
    try {
      await medplum.deleteResource('Slot', slotId);
      onAppointmentsUpdated();
      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Slot deleted',
      });
    } catch (err) {
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        title: 'Error',
        message: normalizeErrorString(err),
      });
    }
    handlers.close();
  }

  async function handleSubmit(): Promise<void> {
    if (!formData.patientRef) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Please select a patient',
      });
      return;
    }

    if (!formData.appointmentType || !formData.serviceType) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Please fill in all required fields',
      });
      return;
    }

    try {
      const appointment: Appointment = {
        resourceType: 'Appointment',
        status: 'booked',
        slot: [createReference(slot as Slot)],
        description: formData.comment,
        appointmentType: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
              code: formData.appointmentType,
              display: AppointmentTypes.find((t) => t.value === formData.appointmentType)?.label,
            },
          ],
        },
        serviceType: [
          {
            coding: [
              {
                system: 'http://hl7.org/fhir/ValueSet/service-type',
                code: formData.serviceType,
                display: formData.serviceType,
              },
            ],
          },
        ],
        participant: [
          {
            actor: {
              reference: `Patient/${formData.patientRef.value}`,
              display: formData.patientRef.label,
            },
            status: 'accepted',
          },
          {
            actor: createReference(profile),
            status: 'accepted',
          },
        ],
        comment: formData.comment,
      };

      // Call bot to create the appointment
      // appointment = await medplum.executeBot({ system: 'http://example.com', value: 'book-appointment' }, appointment);
      const payload = { appointment };
      await bookSlot(medplum, payload);

      // navigate(`/Appointment/${appointment.id}`);
      onAppointmentsUpdated();
      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Appointment created',
      });
    } catch (err) {
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        title: 'Error',
        message: normalizeErrorString(err),
      });
    }

    handlers.close();
  }

  if (!slot) {
    return null;
  }

  return (
    <>
      <Drawer
        position="right"
        size="35%"
        opened={opened}
        onClose={handlers.close}
        title={
          <Box>
            <Text className="header-txt">Create an appointment</Text>
            <Text className="sub-header-txt">Enter details to create an appointment</Text>
          </Box>
        }
      >
        <Divider />

        <Group justify="flex-end" my={'1.3rem'}>
          <ActionIcon
            variant="outline"
            size={33}
            className="button-icon"
            onClick={() => {
              handlers.close();
              updateSlotHandlers.open();
            }}
          >
            <IconEdit size={20} stroke={2.2} />
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            size={33}
            color="red"
            className="button-icon"
            onClick={() => handleDeleteSlot(slot.id as string)}
          >
            <IconTrash size={20} stroke={2.2} />
          </ActionIcon>
        </Group>

        <Box>
          <PatientPractitionerSelect onResourceSelect={handleResourceSelect} />
          <Box className="flex-container">
            <Text span w={'35%'} className="title-txt">
              Appointment type
              <Text span c={'red'}>
                {' '}
                *
              </Text>
            </Text>
            <Select
              placeholder="Select an appointment type"
              className="meeting-title-input-box"
              data={AppointmentTypes}
              value={formData.appointmentType}
              onChange={(value) => setFormData((prev) => ({ ...prev, appointmentType: value || '' }))}
              mb={12}
            />
          </Box>
          <Box className="flex-container">
            <Text span w={'35%'} className="title-txt">
              Appointment service type
              <Text span c={'red'}>
                {' '}
                *
              </Text>
            </Text>
            <Autocomplete
              placeholder="Select an appointment service type"
              className="meeting-title-input-box"
              data={['Telehealth','Pediatric Service','Cardiology Service','Pharmaceutical']}
              value={formData.serviceType}
              onChange={(value) => setFormData((prev) => ({ ...prev, serviceType: value || '' }))}
              mb={12}
            />
          </Box>
          <Box className="flex-container">
            <Text span w={'35%'} className="title-txt">
              Comment
            </Text>
            <TextInput
              placeholder="Enter additional comment"
              className="meeting-title-input-box"
              value={formData.comment}
              onChange={(e) => setFormData((prev) => ({ ...prev, comment: e.currentTarget.value }))}
              mb={12}
            />
          </Box>
        </Box>
        <Divider my={'2rem'} />
        <Group justify="flex-end" mt={20}>
          <Button bg={'#3ca5a9'} radius={4} onClick={handleSubmit}>
            Save
          </Button>
        </Group>
      </Drawer>

      <CreateUpdateSlot
        event={event}
        opened={updateSlotOpened}
        handlers={updateSlotHandlers}
        onSlotsUpdated={onAppointmentsUpdated}
        schedule={schedule}
      />
    </>
  );
}

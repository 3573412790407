/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import { Loading, useMedplumContext } from '@medplum/react';
import { Box, Grid, Paper } from '@mantine/core';
import { useParams } from 'react-router-dom';
import ZoomMeeting from '../../Zoom/video/ZoomMeeting';
import ZoomContext from '../../Zoom/context/zoom-context';
import ZoomVideo, { Participant } from '@zoom/videosdk';
import { Patient } from '@medplum/fhirtypes';
import { getSavedSession, getZoomSignature } from '../../utils/CustomAPI';
import PatientDetails from './PatientDetails';
import CallSessionEnded from './TelehealthComponents/CallSessionEnded';
import LiveKitMeeting from '.././../LiveKit/LiveKitMeeting';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';

interface MeetingDetails {
  signature: string;
  topic: string;
  name: string;
  password: string;
}

const Meeting: React.FC = () => {
  const { medplum, profile } = useMedplumContext();
  const { id: appointmentId } = useParams<{ id: string }>();
  const [appointment, setAppointment] = useState<any>();
  const [meetingDetails, setMeetingDetails] = useState<MeetingDetails | null>(null);
  const [displayMeeting, setDisplayMeeting] = useState<boolean>(false);
  const zmClient = ZoomVideo.createClient();
  const activeUserType = profile?.resourceType;
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [transcription, setTranscription] = useState<string | undefined>('');
  const [isRightSectionVisible, setIsRightSectionVisible] = useState(false);
  const [clinicalNotes, setClinicalNotes] = useState('');
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);
  const [isEndingSession, setIsEndingSession] = useState(false);
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [sdkType, setSdkType] = useState<string>('zoom');
  const liveKitServerUrl = useRef<string>('');

  // Fetch appointment details
  const fetchAppointmentDetails = async (appointmentId: string): Promise<void> => {
    try {
      const appointment = await medplum.readResource('Appointment', appointmentId);
      if (!appointment) {
        throw new Error('Appointment not found');
      }

      const participants = appointment?.participant?.map((part: any) => ({
        reference: part.actor.reference,
        display: part.actor.display,
      }));

      const patientId = participants?.find((part: any) => part.reference.includes('Patient'))?.reference?.split('/')[1];
      const patient = await medplum.readResource('Patient', patientId);
      setPatient(patient);
      setAppointment(appointment);
    } catch (error) {
      console.error('Error fetching appointment details:', error);
    }
  };

  useEffect(() => {
    const fetchLiveKitServerUrl = async () => {
      liveKitServerUrl.current = 'wss://telehealth-syl75e0s.livekit.cloud';
    };

    fetchLiveKitServerUrl().catch((err) => {
      console.error('Error fetching liveKit server URL', err);
    });
  }, []);

  // Get Zoom signature details
  const getSignatureDetails = async (): Promise<void> => {
    const role = activeUserType === 'Patient' ? 0 : 1;
    const body = {
      appointmentId: appointmentId,
      role_type: role,
    };

    try {
      const session = await getZoomSignature(medplum, body);
      if (session) {
        const { sdkJWT, sdkType } = session;

        const participants = appointment.participant?.map((part: any) => ({
          reference: part.actor.reference,
          display: part.actor.display,
        }));

        let name: string;
        if (participants) {
          name = activeUserType === 'Practitioner' ? participants[1]?.display : participants[0]?.display;
        }

        setSdkType(sdkType);

        setMeetingDetails((prevDetails: any) => ({
          ...prevDetails,
          signature: sdkJWT,
          topic: appointmentId,
          name: name,
        }));

        setDisplayMeeting(true);
      }
    } catch (error) {
      console.error('Error joining Zoom meeting:', error);
    }
  };

  // Fetch appointment details on component mount
  useEffect(() => {
    if (appointmentId) {
      fetchAppointmentDetails(appointmentId).catch((err) => {
        console.error('Error fetching appointment details', err);
      });
    }
  }, [appointmentId, medplum]);

  // Set right section visibility
  useEffect(() => {
    if (documentId) {
      setIsRightSectionVisible(true);
    }
  }, [documentId]);

  // Get end session details
  const getSavedSessionDetails = async (): Promise<void> => {
    try {
      await getSavedSession(medplum, appointmentId as string).then((session: any) => {
        if (session) {
          setDocumentId(session.documentId);
          setClinicalNotes(session.clinicalNote);
          setTranscription(session.transcriptData.data);
          setIsSessionEnded(true);
        }
      });
    } catch (error) {
      console.error('Error getting end session details', error);
    }
  };

  // Get signature details when appointment is set
  useEffect(() => {
    if (appointment) {
      if (appointment.status === 'fulfilled') {
        setIsRightSectionVisible(true);
        setIsSessionEnded(true);
        getSavedSessionDetails().catch((err: Error) => {
          console.error('Error getting end session details', err);
        });
      } else {
        getSignatureDetails().catch((err) => {
          console.error('Error getting signature details', err);
        });
      }
    }
  }, [appointment]);

  // Render Zoom meeting component
  const renderZoomMeeting = (): JSX.Element => {
    if (!displayMeeting || !zmClient || !meetingDetails) {
      return <Loading />;
    }

    return (
      <ZoomContext.Provider value={zmClient}>
        <ZoomMeeting
          meetingArgs={{
            topic: meetingDetails.topic,
            signature: meetingDetails.signature,
            name: meetingDetails.name,
            webEndpoint: 'zoom.us',
            setIsRightSectionVisible: setIsRightSectionVisible,
            isRightSectionVisible: isRightSectionVisible,
          }}
          setTranscription={setTranscription}
          appointment={appointment}
          setClinicalNotes={setClinicalNotes}
          setDocumentId={setDocumentId}
          isEndingSession={isEndingSession}
          setIsEndingSession={setIsEndingSession}
          setIsSessionEnded={setIsSessionEnded}
          participants={participants}
          setParticipants={setParticipants}
          transcription={transcription}
        />
      </ZoomContext.Provider>
    );
  };


  const renderLiveKitMeeting = (): JSX.Element => {
    if (!meetingDetails || !liveKitServerUrl.current) {
      return <Loading />;
    }

    return (
      <LiveKitMeeting 
        token={meetingDetails.signature} 
        serverUrl={liveKitServerUrl.current} 
        meetingArgs={{
          setIsRightSectionVisible: setIsRightSectionVisible,
          isRightSectionVisible: isRightSectionVisible,
        }}
        appointment={appointment}
        setClinicalNotes={setClinicalNotes}
        setDocumentId={setDocumentId}
        setIsSessionEnded={setIsSessionEnded}
        setIsEndingSession={setIsEndingSession}
      />
    ) 
  };


  // Render end session component
  const renderEndSession = (): JSX.Element => {
    return (
      <CallSessionEnded appointmentId={appointmentId || ''} documentId={documentId} />
    );
  };

  return (
    <Box h={'100%'}>
      <Paper className="resource-tab-panel patient-list">
        <Grid h={'100%'}>
          {!isSessionEnded ? (
            <Grid.Col span={{md: isRightSectionVisible ? 6.5 : 12}}>
              {sdkType === 'zoom' ? renderZoomMeeting() : renderLiveKitMeeting()}
            </Grid.Col>
          ) : (
            <Grid.Col span={{md: isRightSectionVisible ? 6.5 : 12}} h={'100%'}>
              {renderEndSession()}
            </Grid.Col>
          )}

          {isRightSectionVisible && (
            <Grid.Col span={5.5}>
              {patient && appointment ? (
                <PatientDetails
                  patient={patient}
                  transcription={transcription ?? ''}
                  clinicalNotes={clinicalNotes}
                  documentId={documentId}
                  isSessionEnded={isSessionEnded}
                  isEndingSession={isEndingSession}
                  setDocumentId={setDocumentId}
                />
              ) : (
                <Loading />
              )}
            </Grid.Col>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Meeting;

import { ActionIcon, Box, Button, Divider, Drawer, Group, MultiSelect, Text, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { createReference, normalizeErrorString } from '@medplum/core';
import { Reference, Schedule } from '@medplum/fhirtypes';
import { Loading, useMedplum } from '@medplum/react';
import { IconCircleCheck, IconCircleOff, IconClock } from '@tabler/icons-react';
import { createSchedule } from '../../../utils/CustomAPI';
import { DateInput, TimeInput } from '@mantine/dates';
import { useRef, useState } from 'react';
import { DaysOfWeek } from '../../../utils/constant';

interface SetAvailabilityProps {
  readonly opened: boolean;
  readonly handlers: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  schedule: Schedule;
}

interface SetAvailabilityEvent {
  schedule: Reference<Schedule>;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  duration: number;
  daysOfWeek: string[];
  timezoneOffset: number;
}

export function SetAvailability(props: SetAvailabilityProps): JSX.Element {
  const { opened, handlers, schedule } = props;

  const medplum = useMedplum();
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [duration, setDuration] = useState<number>(60);
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);

  if (!schedule) {
    return <Loading />;
  }

  async function handleSubmit(): Promise<void> {
    try {
      // Adjust dates to prevent timezone offset issues
      const adjustStartDate = startDate ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000) : null;
      const adjustEndDate = endDate ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000) : null;

      const input: SetAvailabilityEvent = {
        schedule: createReference(schedule),
        startDate: adjustStartDate?.toISOString().slice(0, 10) || '',
        endDate: adjustEndDate?.toISOString().slice(0, 10) || '',
        startTime,
        endTime,
        duration,
        daysOfWeek,
        timezoneOffset: new Date().getTimezoneOffset(),
      };

      await createSchedule(medplum, input);

      // Refresh the page to show the new slots
      window.location.reload();
      
      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Slots created',
      });
    } catch (err) {
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        title: 'Error',
        message: normalizeErrorString(err),
      });
    }

    handlers.close();
  }

  const createPickerControl = (inputRef: React.RefObject<HTMLInputElement>): JSX.Element => (
    <ActionIcon variant="subtle" color="gray" onClick={() => inputRef.current?.showPicker()}>
      <IconClock size={16} stroke={1.5} />
    </ActionIcon>
  );

  return (
    <Drawer
      position="right"
      size="35%"
      opened={opened}
      onClose={handlers.close}
      title={
        <Box>
          <Text className="header-txt">Set Availability</Text>
          <Text className="sub-header-txt">Set availability for this schedule</Text>
        </Box>
      }
    >
      <Divider />
      <Box className="flex-container" mt={'1.3rem'}>
        <Text span w={'35%'} className="title-txt">
          Start Date
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <DateInput
          clearable
          value={startDate}
          onChange={(date) => setStartDate(date)}
          placeholder="Date input"
          className="meeting-title-input-box"
          mb={12}
          minDate={new Date()}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          End Date
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <DateInput
          clearable
          value={endDate}
          onChange={(date) => setEndDate(date)}
          placeholder="dd/mm/yyyy"
          className="meeting-title-input-box"
          mb={12}
          minDate={startDate || new Date()}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          Start Time (each day)
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TimeInput
          ref={startTimeRef}
          value={startTime}
          onChange={(e) => setStartTime(e.currentTarget.value || '')}
          rightSection={createPickerControl(startTimeRef)}
          className="meeting-title-input-box"
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          End Time (each day)
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TimeInput
          ref={endTimeRef}
          value={endTime}
          onChange={(e) => setEndTime(e.currentTarget.value || '')}
          rightSection={createPickerControl(endTimeRef)}
          placeholder="hh:mm"
          className="meeting-title-input-box"
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          Duration of each slot (minutes)
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TextInput
          value={duration}
          onChange={(e) => setDuration(parseInt(e.currentTarget.value, 10) || 0)}
          placeholder="hh:mm"
          className="meeting-title-input-box"
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          Days of Week
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <MultiSelect
          value={daysOfWeek}
          onChange={(value) => setDaysOfWeek(value)}
          placeholder="Select days of week"
          data={DaysOfWeek}
          className="meeting-title-input-box"
          mb={12}
          multiple
        />
      </Box>
      <Divider />
      <Group justify="flex-end" mt={20}>
        <Button bg={'#3ca5a9'} radius={4} onClick={handleSubmit}>
          Submit
        </Button>
      </Group>

      {/* <QuestionnaireForm
        // questionnaire={setAvailabilityQuestionnaire}
        // subject={createReference(schedule)}
        // onSubmit={handleQuestionnaireSubmit}
      /> */}
    </Drawer>
  );
}

import { Button, Popover } from '@mantine/core';
import { useState } from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal';

interface LeaveButtonProps {
  onEndClick: () => void;
  isEndingSession: boolean;
}

const LeaveButton = (props: LeaveButtonProps): JSX.Element => {
  const { onEndClick, isEndingSession } = props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const onConfirm = (): void => {
    onEndClick();
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <Popover
        width={200}
        position="bottom"
        shadow="md"
        styles={{
          dropdown: {
            top: '-103px !important',
          },
        }}
      >
          <Button
            variant="filled"
            radius="md"
            title="Leave session"
            w="auto"
            ml="md"
            fs="14"
            fw={600}
            h={45}
            bg={'#D3302F'}
            style={{
              '&:hover': {
                backgroundColor: '#E34234',
              },
            }}
            disabled={isEndingSession}
            onClick={() => {
              setIsConfirmationModalOpen(true);
            }}
          >
           {isEndingSession ? 'Generating Notes...' : 'End Call'} 
          </Button>
   
      </Popover>
      <ConfirmationModal
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        onConfirm={onConfirm}
        isConfirmationModalOpen={isConfirmationModalOpen}
        content={'You will end this for everyone because you are the initiator of the call.'}
      />
    </>
  );
};

export { LeaveButton };

import { Box, Select, Text } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { fetchPatients } from '../utils/CustomAPI';
import { useMedplumContext } from '@medplum/react';

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
  ${type}(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    name {
      family
      given
    }
  }
}`;

interface PatientPractitionerSelectProps {
  practitioner?: boolean;
  onResourceSelect?: (resource: { value: string; label: string } | undefined) => void;
}

const PatientPractitionerSelect = ({
  practitioner = false,
  onResourceSelect,
}: PatientPractitionerSelectProps): JSX.Element => {
  const [resources, setResources] = useState<{ value: string; label: string }[]>([]);
  const [resource, setResource] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { medplum } = useMedplumContext();

  const resourceType = practitioner ? 'PractitionerList' : 'PatientList';

  // Function to fetch resources (patients or practitioners) based on search term
  const fetchResources = useCallback(
    (
      type: 'PatientList' | 'PractitionerList',
      searchTerm: string,
      setResource: React.Dispatch<React.SetStateAction<{ value: string; label: string }[]>>
    ) => {
      if (searchTerm.length >= 3) {
        fetchPatients(medplum, defaultQuery(type, searchTerm))
          .then(
            (response: { data: { [key: string]: { id: string; name: { given: string[]; family: string }[] }[] } }) => {
              const resourceList = response.data[type].map((resource) => {
                const givenName = resource?.name?.[0]?.given?.join(' ') || '';
                const familyName = resource?.name?.[0]?.family || '';
                return { value: resource.id, label: `${givenName} ${familyName}` };
              });
              setResource(resourceList);
            }
          )
          .catch((error: Error) => console.error(error));
      } else {
        setResource([]);
      }
    },
    [medplum]
  );

  // Function to handle resource selection
  const handleResourceSelect = (value: string | null) => {
    const selectedResource = resources.find((resource) => resource.value === value) ?? undefined;
    setResource(value ?? undefined);
    if (onResourceSelect) {
      onResourceSelect(selectedResource);
    }
  };

  // Effect to fetch resources when search term changes
  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchResources(resourceType, searchTerm, setResources);
    }
  }, [searchTerm, fetchResources, resourceType]);

  return (
    <Box className="flex-container">
      <Text span w={'35%'} className="title-txt">
        {`Select ${practitioner ? 'Practitioner' : 'Patient'}`}
        <Text span c={'red'}>
          {' '}
          *
        </Text>
      </Text>
      <Select
        placeholder={`Select ${practitioner ? 'Practitioner' : 'Patient'}`}
        className="meeting-title-input-box"
        data={resources}
        value={resource}
        onChange={handleResourceSelect} // Update to use handleResourceSelect
        searchValue={searchTerm}
        onSearchChange={setSearchTerm}
        styles={(theme) => ({
          label: { marginBottom: theme.spacing.xs },
        })}
        mb="12"
        searchable
        required
        clearable
      />
    </Box>
  );
};

export default PatientPractitionerSelect;

import React, { useEffect, useState } from 'react';
import { Box, Group, Tabs } from '@mantine/core';
import { Patient } from '@medplum/fhirtypes';
import './PatientDetails.css';
import TelehealthTranscription from './TelehealthComponents/TelehealthTranscription';
import ClinicalNotes from './TelehealthComponents/ClinicalNotes';
import Attendees from './TelehealthComponents/Attendees';
import CustomTabs from '../../components/CustomTabs';
import { useAppContext } from '../../AppProvider';

interface PatientDetailsProps {
  patient: Patient;
  transcription: string;
  clinicalNotes?: string;
  documentId?: string;
  isSessionEnded: boolean;
  isEndingSession: boolean;
  setDocumentId: (documentId: string) => void;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  patient,
  transcription,
  clinicalNotes,
  documentId,
  isSessionEnded,
  isEndingSession,
  setDocumentId,
}: PatientDetailsProps) => {
  const { transcript } = useAppContext();
  const [activeTab, setActiveTab] = useState<string>('Transcription');
  useEffect(() => {
    if (isEndingSession) {
      setActiveTab('AI Clinical Note');
    }
  }, [isEndingSession]);

  return (
    <Group w={'100%'}>
      <Tabs color="#7F56D9" value={activeTab} defaultValue="Transcription" w={'100%'}>
        <Box display="flex" className="tabs-header">
          <Tabs.List className="tabs-list">
            <CustomTabs
              value="Transcription"
              onClick={() => setActiveTab('Transcription')}
              title="Transcriptions"
              disabled={false}
            />
            <CustomTabs
              value="AI Clinical Note"
              onClick={() => setActiveTab('AI Clinical Note')}
              title={!clinicalNotes ? 'Waiting to Generate Notes...' : ''}
              disabled={!clinicalNotes && !isSessionEnded}
            />
            {!isSessionEnded && (
              <CustomTabs
                value="Attendees"
                onClick={() => setActiveTab('Attendees')}
                title={isSessionEnded ? 'Session Ended' : ''}
                disabled={isSessionEnded}
              />
            )}
            <CustomTabs value="Chat" onClick={() => setActiveTab('Chat')} title="Coming Soon.." disabled={true} />
          </Tabs.List>
        </Box>
        <Tabs.Panel value="Transcription" pt="xs" w={'100%'}>
          <TelehealthTranscription transcription={transcription || transcript} />
        </Tabs.Panel>
        <Tabs.Panel value="AI Clinical Note" pt="xs" w={'100%'}>
          <ClinicalNotes
            clinicalNotes={clinicalNotes}
            documentId={documentId}
            isEndingSession={isEndingSession}
            patient={patient}
            setDocumentId={setDocumentId}
            transcription={transcription || transcript}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Attendees" pt="xs" w={'100%'}>
          <Attendees patient={patient} />
        </Tabs.Panel>
      </Tabs>
    </Group>
  );
};

export default PatientDetails;

import React, { useEffect, useState } from 'react';
import { Text, Switch, Drawer, Box } from '@mantine/core';
import InstantAppointmentForm from '../../scheduling/components/InstantAppointmentForm';
import { useLocation } from 'react-router-dom';

interface TelehealthDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onStartCall: (id: string) => void;
}

export const TelehealthDrawer: React.FC<TelehealthDrawerProps> = ({ isOpen, onClose, onStartCall }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/Telehealth')) {
      setMeetingType('instant');
    } else if (location.pathname.includes('/Schedule')) {
      setMeetingType('schedule');
    }
  }, [location]);
  const [title, setTitle] = useState<string>('');
  const [practitioner, setPractitioner] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<string | undefined>(undefined);
  const [meetingType, setMeetingType] = useState<'instant' | 'schedule'>('instant');
  const [patients, setPatients] = useState<{ value: string; label: string }[]>([]);
  const [isStarting, setIsStarting] = useState<boolean>(false);
  const patientName = patients?.[0]?.label ?? undefined;

  // Function to close the dialog and reset states
  const closeDialog = (): void => {
    setIsStarting(false);
    setTitle('');
    setPractitioner(undefined);
    setPatient(undefined);
    onClose();
  };

  return (
    <Drawer
      className="drawer"
      position="right"
      opened={isOpen}
      onClose={closeDialog}
      title={
        <Box className="header">
          <Text className="header-txt">Create a Telehealth Meeting</Text>

          <Text className="sub-header-txt">
            Enter details to start a meeting with <span className="patient-name">{patientName ?? 'Patient'}</span>
          </Text>
        </Box>
      }
      size="35%"
    >
      <Box className="meeting-type-switch">
        <Text
          style={{
            fontWeight: meetingType === 'schedule' ? '500' : '400',
            fontSize: '14px',
            color: meetingType === 'schedule' ? '#0D121C' : '#A0A0A0',
          }}
        >
          Schedule
        </Text>
        <Switch
          defaultChecked
          className="checkbox"
          size="sm"
          onChange={() => setMeetingType(meetingType === 'instant' ? 'schedule' : 'instant')}
          my="xl"
        />
        <Text style={{ fontWeight: meetingType === 'instant' ? '500' : '400', fontSize: '14px' }}>Instant</Text>
      </Box>
      <InstantAppointmentForm
        meetingType={meetingType}
        title={title}
        practitioner={practitioner}
        patient={patient}
        setPatient={setPatient}
        isStarting={isStarting}
        setIsStarting={setIsStarting}
        closeDialog={closeDialog}
        onStartCall={onStartCall}
        setTitle={setTitle}
        setPatients={setPatients}
        patients={patients}
      />
    </Drawer>
  );
};

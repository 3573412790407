import React from 'react';
import { Badge, Text } from '@mantine/core';
import './StatusBadge.css';

interface StatusBadgeProps {
  statusColor: string;
  statusLabel: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  statusColor,
  statusLabel,
  borderColor,
  textColor = '#333333',
  backgroundColor,
}) => {
  return (
    <Badge
      variant="outline"
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      }}
      radius={16}
      py={10}
    >
      <span
        className="status-circle"
        style={{
          backgroundColor: statusColor,
        }}
      ></span>
      <Text span ml={'auto'}
        className="status-text"
        style={{
          color: textColor,
        }}
      >
        {statusLabel}
      </Text>
    </Badge>
  );
};

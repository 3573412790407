import { ActionIcon, Box, Flex, Group, NativeSelect, Text } from '@mantine/core';
import { IconCircleMinus, IconCirclePlus, IconRefresh } from '@tabler/icons-react';
import './Components.css';
import { useState } from 'react';

interface TelehealthTranscriptionProps {
  transcription: string;
}

const TelehealthTranscription = ({ transcription }: TelehealthTranscriptionProps): JSX.Element => {
  const [fontSize, setFontSize] = useState<number>(14);
  const handleRefresh = (): void => {
    setFontSize(14);
  };
  return (
    <Box w={'100%'}>
      <Group display={'flex'} pt={8} pb={12}>
        <Flex align={'center'}>
          <Text size={'xs'} c="#667085" fw={500}>
            Converted to
          </Text>

          <NativeSelect className="transcription-language" size={'xs'} data={['English']} />
          <ActionIcon variant="transparent" title="Reset" onClick={handleRefresh}>
            <IconRefresh size={16} className='icon-color' />
          </ActionIcon>
        </Flex>

        <Flex align={'center'} gap={16}>
          <Text size={'xs'} c="#667085" fw={500}>
            Font Size
          </Text>
          <Box ml={5} className="flex-center gap-8">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setFontSize(fontSize + 1);
              }}
            >
              <IconCirclePlus color="#667085" size={14} />
            </ActionIcon>{' '}
            <Text size={'xs'} fw={700}>
              {fontSize}
            </Text>{' '}
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setFontSize(fontSize - 1);
              }}
            >
              <IconCircleMinus size={14} color="#667085" />
            </ActionIcon>
          </Box>
        </Flex>
      </Group>

      <Box className="transcription-text">
        {transcription
          .split('\n')
          .map((line, index) => {
            const speakerMatch = line.match(/^(Speaker \d+(?:\s\([^)]+\))?):\s*(.*)$/);

            if (speakerMatch) {
              const [, speakerPrefix, restOfText] = speakerMatch;

              return (
                <Text c="#101828" className="transcription-text-line" key={index} fz={fontSize} >
                  <span className="speaker-prefix">{speakerPrefix}:</span> {restOfText}
                  <br />
                </Text>
              );
            }

            return null;
          })
          .filter(Boolean)}
      </Box>
    </Box>
  );
};

export default TelehealthTranscription;

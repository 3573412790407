/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Checkbox, Loader, Modal, Select, TextInput } from '@mantine/core';
import { IconCalendarMonth, IconFlag } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { DateInput } from '@mantine/dates';
import { useMedplum } from '@medplum/react';
import ServiceInfo from '../../components/ServiceInfo';
import { checkInputValue, formatDate } from '../../utils/util';
import ContactInfo from '../../components/ContactInfo';
import { createPatient } from './PatientResource';
import { createUpdatePatient, createUpdatePatientAccount, createUpdatePatientResource } from '../../utils/CustomAPI';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import { Coverage, Patient } from '@medplum/fhirtypes';
import { useNavigate } from 'react-router-dom';

interface AddPatientProps {
  opened: boolean;
  patientClose: () => void;
  patientId?: string;
  patientData: any;
}

const AddPatient: React.FC<AddPatientProps> = (props: AddPatientProps) => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialFormData = {
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    mobile: '',
    email: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    insuranceNumber: '',
    createAccount: false,
    service: '',
    providerGroup: '',
    provider: '',
    userId: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (props.patientId) {
      const telecomMap = props?.patientData?.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
      setFormData(prev => ({
        ...prev,
        firstName: props.patientData.name[0].given[0],
        lastName: props.patientData.name[0].family,
        dob: props.patientData.birthDate,
        gender: props.patientData.gender,
        street: props.patientData.address?.[0].line?.[0],
        city: props.patientData.address[0].city,
        state: props.patientData.address[0].state,
        postalCode: props.patientData.address[0].postalCode,
        mobile: telecomMap['phone'] || '',
        email: telecomMap['email'] || '',
        insuranceNumber: props.patientData.insuranceNumber || '',
        providerGroup: props.patientData?.managingOrganization.display,
        provider: props.patientData?.generalPractitioner[0]?.display,
        userId: props.patientData.userId,
      }));
    }
  }, [props.patientData]);

  const handleOnSelect = (name: any, e: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e,
    }));
  };

  // Handle input change value and set form data
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  };

  // Handle checkbox change and set form data
  const handleCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // handle date change
  const handleDateChange = (date: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      dob: formatDate(date),
    }));
  };

  const handleSubmit = () => {
    //create patien resource
    const patient = createPatient(formData, props.patientId);
    setLoading(true);
    createUpdatePatient(medplum, patient)
    .then((res) => {
      createUpdatePatientDataResource(res);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
      showNotification({ color: 'red', message: normalizeErrorString(err) });
    });
  }

  const createUpdatePatientDataResource = (res: Patient) => {
    //create coverage resource
    const coverage: Coverage = {
      resourceType: 'Coverage',
      status: 'active',
      beneficiary: {
        reference: `Patient/${res.id}`,
        display: `${formData.firstName} ${formData.lastName}`,
      },
      payor: [
        {
          reference: `Patient/${res.id}`,
          display: `${formData.firstName} ${formData.lastName}`,
        },
      ],
      subscriberId: formData.insuranceNumber,
    };

    //add coverage id if it is update
    if (props.patientData.coverageId) {
      coverage.id = props.patientData.coverageId;
    }
    createUpdatePatientResource(medplum, coverage)
      .then(() => {
        //if create account is checked then create patient account
        if (formData.createAccount) {
          //create patient account
          createAccount(res.id);
        } else {
          setLoading(false);
          showNotification({
            color: 'green',
            message: `${props.patientId ? 'Patient updated successfully' : 'Patient created successfully'}`,
          });
          navigate('/Patient');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  //create patient account
  const createAccount = (profileId: any) => {
    const payload = {
      id: props.patientData.userId,
      profile_id: profileId,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
    };
    createUpdatePatientAccount(medplum, payload)
      .then(() => {
        setLoading(false);
        navigate('/Patient');
        props.patientClose();
        showNotification({
          color: 'green',
          message: `${props.patientId ? 'Patient updated successfully' : 'Patient created successfully'}`,
        });
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  return (
    <Modal.Root opened={props.opened} onClose={props.patientClose} size="lg" className="appointment">
      <Modal.Overlay />
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <div className="appointment_title">
                <IconFlag style={{ transform: 'rotate(345deg)' }} />
              </div>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <h3 className="tw-text-lg tw-font-semibold tw-text-[#101828]">{props.patientId ? 'Update' : 'New'} Patient</h3>
            <p className="tw-text-sm tw-leading-relaxed tw-text-[#475467]">Enter details ro create new patient.</p>
            <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
              <div className="tw-mt-6">
                <ServiceInfo formData={formData} handleOnSelect={(name, e) => handleOnSelect(name, e)}/>
                <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
                <h3 className="tw-text-lg tw-mt-6 tw-font-semibold tw-text-[#101828]">Basic Information</h3>
                <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                  <div className="tw-w-[48%]">
                    <TextInput
                      placeholder="First Name"
                      name="firstName"
                      label={<span className="tw-text-[#475467]">First Name</span>}
                      value={formData?.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="tw-w-[48%]">
                    <TextInput
                      placeholder="Last Name"
                      name="lastName"
                      label={<span className="tw-text-[#475467]">Last Name</span>}
                      value={formData?.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                  <div className="tw-w-[48%]">
                    <DateInput
                      placeholder="Date of Birth"
                      valueFormat="YYYY-MM-DD"
                      name="dob"
                      label={<span className="tw-text-[#475467]">Date of Birth</span>}
                      rightSection={<IconCalendarMonth size="16px" />}
                      required
                      value={formData.dob ? new Date(formData.dob) : null}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className="tw-w-[48%]">
                    <Select
                      data={['Male', 'Female']}
                      placeholder="Gender"
                      label={<span className="tw-text-[#475467]">Gender</span>}
                      value={formData?.gender}
                      name="gender"
                      required
                      onChange={(event) => handleOnSelect('gender', event)}
                    />
                  </div>
                </div>
                <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
                <ContactInfo formData={formData} handleInputChange={handleInputChange} handleOnSelect={(name, e) => handleOnSelect(name, e)} />
                <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                  <div className="tw-w-full">
                  <TextInput
                    placeholder="Insurance Number"
                    name="insuranceNumber"
                    label={<span className="tw-text-[#475467]">Insurance Number</span>}
                    value={formData?.insuranceNumber}
                    onChange={handleInputChange}
                  />
                  </div>
                </div>
                <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                  <Checkbox
                    size="sm"
                    label={<span className="tw-text-[#475467] tw-font-semibold">Create Account</span>}
                    name="createAccount"
                    checked={formData?.userId ? true : formData?.createAccount}
                    onChange={handleCheckBoxChange}
                    disabled={!!formData?.userId}
                  />
                </div>
              </div>
          </Modal.Body>
          <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
          <div className='tw-flex tw-p-4'>
            <button type="button" className="tw-w-1/2 tw-text-[#344054] tw-bg-white tw-font-semibold tw-rounded-lg tw-text-[15px] tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-border tw-border-[#D0D5DD]" onClick={props.patientClose}>Cancel</button>
            <button type="submit" className="tw-w-1/2 tw-text-white tw-bg-[#3CA5A9] tw-font-semibold tw-rounded-lg tw-text-[15px] tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2">
              {loading ? (
              <>
                {props.patientId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="#fff" />
              </>
            ) : props.patientId ? (
              'Update Patient'
            ) : (
              'Create Patient'
            )}
            </button>
          </div>
        </Modal.Content>
      </form>
    </Modal.Root>
  );
};

export default AddPatient;

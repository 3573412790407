/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { IconCalendarCheck, IconChevronLeft, IconChevronRight, IconCloudDownload } from '@tabler/icons-react';
import { Tooltip } from '@mantine/core';

const LaboratoryCard = () => {
  const laboratoryCard = [
    { id: 1, title: 'Thyroid Test', beneficiary: 'Mildred Price', date: '26 Jun 2024'},
    { id: 2, title: 'Kidney Function Test', beneficiary: 'Mildred Price', date: '26 Jun 2024'},
  ]

  const [currentPatientIndex, setCurrentPatientIndex] = useState(0);

  const handleNext = () => {
    setCurrentPatientIndex((prevIndex) => (prevIndex + 1) % laboratoryCard.length);
  };

  const handlePrev = () => {
    setCurrentPatientIndex((prevIndex) => (prevIndex - 1 + laboratoryCard.length) % laboratoryCard.length);
  };
  
  return (
    <div
        className="tw-mt-6 tw-block tw-w-full tw-p-2 tw-pb-4 tw-bg-white tw-border border-[#F2F2F2] tw-rounded-lg"
        >
          <div className='tw-flex tw-items-center tw-p-3 tw-justify-between'>
            <div className='tw-flex'>
              <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#000]">
                  Laboratory Tests
              </h5>
            </div>
            <div className='tw-flex tw-gap-2'>
              <Tooltip label="Previous" color="gray">
                <IconChevronLeft onClick={handlePrev} color="#B1B6B8" size={16} className="tw-cursor-pointer" />
              </Tooltip>
              <Tooltip label="Next" color="gray">
                <IconChevronRight onClick={handleNext} color="#B1B6B8" size={16} className="tw-cursor-pointer" />
              </Tooltip>
            </div>
          </div>
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <div className="tw-relative tw-overflow-hidden tw-w-full">
            <div className="tw-flex tw-transition-transform tw-duration-500" style={{ transform: `translateX(-${currentPatientIndex * 100}%)` }}>
              {laboratoryCard.map((data, index) => (
                <div key={index} className="tw-flex-shrink-0 tw-w-full">
                  <div className='tw-p-3 tw-justify-between'>
                      <div className='tw-w-full tw-text-[#868686]'>
                          <div className='tw-flex tw-mb-1 tw-items-center'>
                              <span className='tw-w-2/4 tw-text-[12px] tw-text-[#344054]'>REP0912</span>
                              <span className='tw-w-full tw-text-[14px] tw-font-bold tw-text-[#121926]'>: {data.title}</span>
                          </div>
                          <div className='tw-flex tw-mb-1 tw-items-center'>
                              <span className='tw-w-2/4 tw-w-2/4 tw-text-[12px] tw-text-[#344054]'>Beneficiary</span>
                              <span className='tw-w-full tw-text-[14px] tw-font-bold tw-text-[#121926]'>: {data.beneficiary}</span>
                          </div>
                      </div>
                  </div>
                  <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className='tw-flex tw-items-center tw-p-3 tw-pt-4 tw-pb-0 tw-justify-between'>
                    <div className='tw-flex tw-items-start'>
                        <IconCalendarCheck className='tw-mr-2 icon-color' size={20}/>
                        <span className='tw-text-[12px] tw-text-[#475467] tw-font-medium'>{data.date}</span>
                    </div>
                    <IconCloudDownload className='icon-color' size={20} />
                  </div>
                </div>
              ))}
            </div>
          </div>
    </div>
  )
}

export default LaboratoryCard

import { Box, Button, Divider, Group, Paper, Text} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { getReferenceString } from '@medplum/core';
import { Appointment, Practitioner, Schedule, Slot } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer, Event, EventPropGetter } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import { BlockAvailability } from '../components/actions/BlockAvailability';
import { CreateAppointment } from '../components/actions/CreateAppointment';
import { CreateUpdateSlot } from '../components/actions/CreateUpdateSlot';
import { SetAvailability } from '../components/actions/SetAvailability';
import { SlotDetails } from '../components/SlotDetails';
import { IconCalendarEvent } from '@tabler/icons-react';
import { getCurrentWeek } from '../../utils/util';
import './Calendar.css';

/**
 * Schedule page that displays the practitioner's schedule.
 * Allows the practitioner to set availability, block availability, create/update slots, and create
 * appointments.
 * @returns A React component that displays the schedule page.
 */
export function SchedulePage(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();

  const [blockAvailabilityOpened, blockAvailabilityHandlers] = useDisclosure(false);
  const [setAvailabilityOpened, setAvailabilityHandlers] = useDisclosure(false);
  const [slotDetailsOpened, slotDetailsHandlers] = useDisclosure(false);
  const [createUpdateSlotOpened, createUpdateSlotHandlers] = useDisclosure(false);
  const [createAppointmentOpened, createAppointmentHandlers] = useDisclosure(false);

  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const scheduleId = window.location.pathname.split('/')[2];
  const [schedule, setSchedule] = useState<Schedule>();
  const profile = useMedplumProfile() as Practitioner;

  const [shouldRefreshCalender, setShouldRefreshCalender] = useState(false);
  const [slots, setSlots] = useState<Slot[]>();
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>(getCurrentWeek());

  // First load the schedule
  useEffect(() => {
    if (scheduleId) {
      medplum
        .readResource('Schedule', scheduleId)
        .then((loadedSchedule) => {
          setSchedule(loadedSchedule);
          setShouldRefreshCalender(true);
        })
        .catch((error) => {
          console.error('Error loading schedule:', error);
        });
    }
  }, [medplum, scheduleId]);

  const eventPropGetter: EventPropGetter<Event> = (event) => {
    const newStyle = {
      backgroundColor: 'var(--primary-color)',
      border: 'none'
    };
  
    if (event.title === 'Blocked') {
      newStyle.backgroundColor = 'var(--tertiary-color)';
    }
  
    return {
      className: '',
      style: newStyle
    };
  };

  // After schedule is loaded, handle data fetching
  useEffect(() => {
    async function searchSlots(): Promise<void> {
      if (!schedule) {
        return;
      }

      const slots = await medplum.searchResources(
        
        'Slot',
        {
          schedule: `Schedule/${scheduleId}`,
          start: `ge${dateRange.start.toISOString()}`,
          _count: '100',
        },
        { cache: 'no-cache' }
      );
      setSlots(slots);
    }

    async function searchAppointments(): Promise<void> {
      if (!profile) {
        return;
      }

      const appointments = await medplum.searchResources(
        'Appointment',
        {
          actor: getReferenceString(profile),
          start: `ge${dateRange.start.toISOString()}`,
        },
        { cache: 'no-cache' }
      );
      setAppointments(appointments);
    }

    if (shouldRefreshCalender) {
      Promise.allSettled([searchSlots(), searchAppointments()])
        .then(() => setShouldRefreshCalender(false))
        .catch(console.error);
    }
  }, [medplum, schedule, profile, shouldRefreshCalender, dateRange]);

  // Converts Slot resources to big-calendar Event objects
  // Only show free and busy-unavailable slots
  const slotEvents: Event[] = (slots ?? [])
    .filter((slot) => slot.status === 'free' || slot.status === 'busy-unavailable')
    .map((slot) => ({
      title: slot.status === 'free' ? '' : 'Blocked',
      start: new Date(slot.start),
      end: new Date(slot.end),
      resource: slot,
    }));

  // Converts Appointment resources to big-calendar Event objects
  // Exclude cancelled appointments to prevent them from overlapping free slots during rendering
  const appointmentEvents: Event[] = (appointments ?? [])
    .filter((appointment) => appointment.status !== 'cancelled')
    .map((appointment) => {
      // Find the patient among the participants to use as title
      const patientParticipant = appointment?.participant?.find((p) => p.actor?.reference?.startsWith('Patient/'));
      const status = !['booked', 'arrived', 'fulfilled'].includes(appointment.status as string)
        ? ` (${appointment.status})`
        : '';

      return {
        title: `${patientParticipant?.actor?.display} ${status}`,
        start: new Date(appointment.start as string),
        end: new Date(appointment.end as string),
        resource: appointment,
      };
    });

  /**
   * When a date/time range is selected, set the event object and open the create slot modal
   */
  const handleSelectSlot = useCallback(
    (event: Event & { action?: string }) => {
      if (event.action !== 'select') {
        return;
      }
      setSelectedEvent(event);
      createUpdateSlotHandlers.open();
    },
    [createUpdateSlotHandlers]
  );

  /**
   * When an existing event (slot/appointment) is selected, set the event object and open the
   * appropriate modal.
   * - If the event is a free slot, open the create appointment modal.
   * - If the event is a busy-unavailable slot, open the slot details modal.
   * - If the event is an appointment, navigate to the appointment page.
   */
  const handleSelectEvent = useCallback(
    (event: Event) => {
      const { resourceType, status, id } = event.resource;

      function handleSlot(): void {
        setSelectedEvent(event);
        if (status === 'free') {
          createAppointmentHandlers.open();
        } else {
          slotDetailsHandlers.open();
        }
      }

      function handleAppointment(): void {
        navigate(`/Appointment/${id}`);
      }

      if (resourceType === 'Slot') {
        handleSlot();
        return;
      }

      if (resourceType === 'Appointment') {
        handleAppointment();
      }
    },
    [slotDetailsHandlers, createAppointmentHandlers, navigate]
  );

  const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }) => {
    if (Array.isArray(range)) {
      const start = new Date(range[0]);
      const end = new Date(range[range.length - 1]);
      console.log(start, end);
      setDateRange({ start, end });
    } else {
      const start = new Date(range.start);
      const end = new Date(range.end);
      console.log(start, end);
      setDateRange({ start, end });
    }
    setShouldRefreshCalender(true);
  }, []);

  return (
    <Paper radius={'12px'} className="page_container" p={'lg'}>
      <Box display={'flex'} mb={'lg'}>
        <Group ml={'lg'} display={'flex'}>
          <IconCalendarEvent color={'var(--btn-color)'} size={'40'} />
          <Box>
            <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>My Schedule</Text>
            <Text c={'#475467'}>Manage your availability and appointments</Text>
          </Box>
        </Group>
        <Group ml={'auto'} mr={'lg'}>
          <Button size="md" radius="8px" color={'var(--btn-color)'} onClick={() => setAvailabilityHandlers.open()}>
            Set Availability
          </Button>
          <Button
            size="md"
            radius="8px"
            variant="outline"
            color={'var(--btn-color)'}
            onClick={() => blockAvailabilityHandlers.open()}
          >
            Block Availability
          </Button>
        </Group>
      </Box>
      <Divider mb={'lg'} />
      <Box w={'100%'} h={'100%'}>
        <Paper  py={'lg'} radius={'12px'}>
          <Calendar
            defaultView="week"
            views={['month', 'week', 'day', 'agenda']}
            localizer={dayjsLocalizer(dayjs)}
            events={[...appointmentEvents, ...slotEvents]} // Combine appointmentEvents and slotEvents
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            onRangeChange={handleRangeChange}
            scrollToTime={new Date()} // Default scroll to current time
            style={{ height: 600 }}
            selectable
            eventPropGetter={eventPropGetter} 
          />
        </Paper>
      </Box>
      {/* {/ Modals /} */}
      <SetAvailability
        opened={setAvailabilityOpened}
        handlers={setAvailabilityHandlers}
        schedule={schedule as Schedule}
      />
      <BlockAvailability
        opened={blockAvailabilityOpened}
        handlers={blockAvailabilityHandlers}
        schedule={schedule as Schedule}
      />
      <CreateUpdateSlot
        event={selectedEvent}
        opened={createUpdateSlotOpened}
        handlers={createUpdateSlotHandlers}
        onSlotsUpdated={() => setShouldRefreshCalender(true)}
        schedule={schedule as Schedule}
      />
      <SlotDetails
        event={selectedEvent}
        opened={slotDetailsOpened}
        handlers={slotDetailsHandlers}
        onSlotsUpdated={() => setShouldRefreshCalender(true)}
        schedule={schedule as Schedule}
      />
      <CreateAppointment
        event={selectedEvent}
        opened={createAppointmentOpened}
        handlers={createAppointmentHandlers}
        onAppointmentsUpdated={() => setShouldRefreshCalender(true)}
        schedule={schedule as Schedule}
      />
    </Paper>
  );
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from './axios';
import { MedplumClient } from '@medplum/core';
import {
  BookSlotPlayload,
  getZoomSignaturePayloadProps,
  SetAvailabilityEventPlayload,
  SubmitNoteFeedbackPlayload,
  submitTranscriptionPayloadProps,
  UpdateClinicalNotePayload,
  GetFeedbackListPayload,
  BlockAvailabilityPayload,
  CancelSlotPayload,
} from './interface';

export const listingPage = async (medplum: MedplumClient, apiUrl: string, count?: any, offset?: any, search?: any) => {
  const token = await medplum.getAccessToken();
  let queryParameters = '';
  let sortingParameters = '';
  let status = '';
  let statusParam = '';
  // Loop through the filters array to build the query string
  if (search?.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }
  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }

  if (location.pathname === '/WaitingList') {
    status = 'waitlist';
  } else {
    status = 'any';
  }

  if (location.pathname === '/WaitingList') {
    statusParam = `&status=${status}`;
  }
  const response = await axios.get(
    `/healthside/${apiUrl}?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}${statusParam}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response;
};

export const getSlots = async (medplum: MedplumClient, serviceId: string, startDate: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(
    `/fhir/R4/Slot?_count=20&_offset=0&_sort=start&_total=accurate&schedule.actor=HealthcareService/${serviceId}&status=free&&start=ge${startDate}T00:00:00Z&start=lt${startDate}T23:59:00Z`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response;
};

export const bookAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/book-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const updateAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/update-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const cancelAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/cancel-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const createUpdatePatient = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdatePatientResource = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patientdata-resource`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdatePatientAccount = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient-account`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getAppointment = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(
    `fhir/R4/Appointment?_count=20&_offset=0&_sort=-_lastUpdated&_total=accurate&patient=Patient/${patientId}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response;
};

export const fetchPatients = async (medplum: MedplumClient, query: string, filters?: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
        filters: filters,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createTelehealthSession = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-telehealth-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getTelehealthAlerts = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/healthside/get-telehealth-alerts`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response.data;
};

export const getZoomSignature = async (medplum: MedplumClient, payload: getZoomSignaturePayloadProps) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/join-telehealth-session`, payload, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getSavedSession = async (medplum: MedplumClient, appointmentId: string) => {
  try {
    const token = await medplum.getAccessToken();

    const response = await axios.get(`/healthside/get-session/${appointmentId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response?.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

export const submitTranscription = async (medplum: MedplumClient, payload: submitTranscriptionPayloadProps) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/end-telehealth-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updateRegenerateClinicalNote = async (medplum: MedplumClient, payload: UpdateClinicalNotePayload) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/update-regenerate-clinical-note`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const exportClinicalNote = async (medplum: MedplumClient, patientId: string) => {
  try {
    const token = await medplum.getAccessToken();

    const response = await axios.get(`/healthside/download-clinical-note?id=${patientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const submitNoteFeedback = async (medplum: MedplumClient, payload: SubmitNoteFeedbackPlayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/healthside/submit-feedback`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const createSchedule = async (medplum: MedplumClient, payload: SetAvailabilityEventPlayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`healthside/create-schedule`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const bookSlot = async (medplum: MedplumClient, payload: BookSlotPlayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`healthside/Book-slot`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};
export const getFeedbackList = async (medplum: MedplumClient, payload: GetFeedbackListPayload) => {
  try {
    const { id, searchType, itemsPerPage, offset, category, startDate, endDate } = payload;
    const token = await medplum.getAccessToken();
    let actor = '';
    if (id) {
      if (searchType === 'practitioner') {
        actor = `&subject=Practitioner/${id}`;
      } else if (searchType === 'patient') {
        actor = `&patient=Patient/${id}`;
      }
    }

    let url = `/healthside/get-feedback-list?_count=${itemsPerPage}${actor}&_offset=${offset}&_sort=-_lastUpdated`;
    if (category !== 'Rating') {
      url += `&category=${category}`;
    } else {
      url += `&category=RATING`;
    }

    if (startDate && endDate) {
      const formattedStartDate = encodeURIComponent(`sa${startDate}`);
      const formattedEndDate = encodeURIComponent(`eb${endDate}`);

      url += `&authored=${formattedStartDate}&authored=${formattedEndDate}`;
    }

    const response = await axios.get(url, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

export const blockAvailability = async (medplum: MedplumClient, payload: BlockAvailabilityPayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`healthside/block-availability`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const cancelSlot = async (medplum: MedplumClient, payload: CancelSlotPayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`healthside/cancel-slot`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};
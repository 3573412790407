import { Avatar, Button, Divider, Group, Text } from '@mantine/core';
import { Patient } from '@medplum/fhirtypes';
import { IconArrowNarrowRight} from '@tabler/icons-react';
import { useAppContext } from '../../../AppProvider';

interface PatientDetailsProps {
  patient: Patient;
}

const Attendees = ({ patient }: PatientDetailsProps): JSX.Element => {
  const { hostParticipants, joinUser} = useAppContext();

  return (
    <div className="attendees-container">
      <div  className="attendee-item">
        <Group>
          <div className="avatar-wrapper">
            <Avatar src={'/placeholder.svg?height=40&width=40'} size={45} radius="xl" />
            <span className={`status-indicator ${hostParticipants.isHost ? 'online' : 'offline'}`}></span>
          </div>
          <div className="attendee-info">
            <Text className="attendee-name">{hostParticipants.hostName}</Text>
            <Text className="attendee-role">{hostParticipants.isHost ? 'Meeting host' : 'Attendee'}</Text>
          </div>
        </Group>
      </div>
      <Divider />
      {joinUser && (
        <div className="attendee-item">
          <Group>
            <div className="avatar-wrapper">
              <Avatar src={'/placeholder.svg?height=40&width=40'} size={45} radius="xl" />
              <span className={`status-indicator online`}></span>
            </div>
            <div className="attendee-info">
              <Text className="attendee-name">{joinUser}</Text>
              <Text className="attendee-role">Attendee</Text>
            </div>
          </Group>
          <Button
            leftSection={<img src="../../img/icons/profile-user.svg" alt="profile" />}
            className="profile-button btn_bg"
            onClick={() => window.open(`/Patient/${patient.id}/details`, '_blank')}
            rightSection={<IconArrowNarrowRight />}
          >
            Go to Profile
          </Button>
        </div>
      )}
    </div>
  );
};

export default Attendees;

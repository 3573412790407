/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

const PatientGraph = () => {
  const data = [
    { name: 'Jan', uv: 4000, pv: 2400},
    { name: 'Feb', uv: 3000, pv: 2210},
    { name: 'Mar', uv: 2000, pv: 2290},
    { name: 'Apr', uv: 2780, pv: 2000},
    { name: 'May', uv: 1890, pv: 2181},
    { name: 'Jun', uv: 2390, pv: 2500},
    { name: 'Jul', uv: 3590, pv: 2200},
    { name: 'Aug', uv: 3690, pv: 2300},
    { name: 'Sep', uv: 3790, pv: 2400},
    { name: 'Oct', uv: 3890, pv: 2500},
    { name: 'Nov', uv: 3990, pv: 2600},
    { name: 'Dec', uv: 4000, pv: 2700},
  ];

  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          {payload.map((entry: any, index: number) => (
            <p key={`item-${index}`} style={{ color: `${entry.color === '#EAECF0' ? '#000' : entry.color}` }}>{`${entry.name} : ${entry.value}`}</p>
          ))}
        </div>
      );
    }
  }

  return (
    <div className="tw-mt-6 tw-block tw-w-full tw-p-2 tw-bg-white tw-border border-[#F2F2F2] tw-rounded-lg">
      <div className="tw-flex tw-items-center tw-p-3 tw-justify-between">
        <div className="tw-flex">
          <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#000]">
            Old Vs New Patients
          </h5>
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <div className="tw-flex tw-items-center tw-p-3 tw-pt-4 tw-pb-0 tw-justify-between">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={35}
          >
            <CartesianGrid stroke="#EAECF0" horizontal={true} vertical={false} />
            <XAxis scale="point" dataKey="name" axisLine={false} tickLine={false} tickMargin={10} />
            <Tooltip content={<CustomTooltip />}/>
            <Bar dataKey="pv" name="New Patients" stackId="a" fill="#2AAAAF" radius={[0, 0, 0, 0]}/>
            <Bar dataKey="uv" name="Old Patients" stackId="a" fill="#EAECF0" radius={[10, 10, 0, 0]}/>
            <Legend  verticalAlign="bottom" wrapperStyle={{ paddingTop: '10px', backgroundColor: '#fff' }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PatientGraph;

import { ActionIcon, Badge, Box, Button, Group, Paper, Tabs, Text, Tooltip } from '@mantine/core';
import { formatSearchQuery, Operator, SearchRequest } from '@medplum/core';
import { Appointment, Bundle } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { IconFilter, IconVideo } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TELEHEALTHTABS } from '../../utils/constant';
import TelehealthTable from './TelehealthTable';
import './Telehealth.css';
import CustomPagination from '../../components/CustomPagination';
import TelehealthFilters from './TelehealthFilters';
import { TelehealthDrawer } from './TelehealthDrawer';
import TelehealthAlert from './TelehealthAlert';

const Telehealth: React.FC = () => {

  const medplum = useMedplum();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Bundle<Appointment> | undefined>(undefined);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 6;
  const [activeTab, setActiveTab] = useState('all');
  const [advancedFilters, setAdvancedFilters] = useState<any>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [statuses, setStatuses] = useState([]);
  
  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    let filters = [
      { code: 'service-type', operator: Operator.EQUALS, value: 'Telehealth' },
      { code: '_sort', operator: Operator.EQUALS, value: '-date' },
    ];

    filters = advancedFilters.length ? [...filters, ...advancedFilters] : filters;

    const query: SearchRequest = {
      resourceType: 'Appointment',
      filters: filters,
      offset: offset,
      count: itemsPerPage,
      total: 'accurate',
    };

    if (activeTab !== 'all') {
      query.filters?.push({ code: 'status', operator: Operator.EQUALS, value: activeTab });
    }

    try {
      const response: any = await medplum.readResource('Appointment', formatSearchQuery(query));
      setData(response.entry);
      setTotalItems(response.total ?? 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAppointmentStatuses = async (): Promise<void> => {
    try {
      const body = {
        url: 'http://hl7.org/fhir/ValueSet/appointmentstatus|4.0.1',
        filter: '',
      };

      const response: any = await medplum.get(`/fhir/R4/ValueSet/$expand?url=${body.url}&filter=${body.filter}`);
      if (response?.expansion?.contains) {
        const statuses = response.expansion.contains;
        setStatuses(statuses);
      }
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  useEffect(() => {
    console.log('Telehealth useEffect');
    fetchData().catch((error) => console.error('Error fetching data:', error));
    getAppointmentStatuses().catch((error) => console.error('Error fetching statuses:', error));
  }, [offset, medplum, activeTab, advancedFilters]);

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const handleRefresh = (): void => {
    setOffset(0);
    setActiveTab('all');
    fetchData().catch((error) => console.error('Error fetching data:', error));
  };

  const handleFilters = (filters: any): void => {
    const { date, location, practitioner, patient } = filters;
    if (!date && !location && !practitioner && !patient) {
      return;
    }
    const practitionerReference = practitioner ? `Practitioner/${practitioner}` : '';
    const locationReference = location ? `Location/${location}` : '';
    const patientReference = patient ? `Patient/${patient}` : '';
    const newDate = date ? new Date(date) : null;
    const nextDate = newDate ? new Date(newDate.setDate(newDate.getDate() + 1)).toISOString() : null;

    const updatedFilters = [
      { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: date },
      { code: 'date', operator: Operator.LESS_THAN, value: nextDate },
      { code: 'location', operator: Operator.EQUALS, value: locationReference },
      { code: 'practitioner', operator: Operator.EQUALS, value: practitionerReference },
      { code: 'patient', operator: Operator.EQUALS, value: patientReference },
    ]?.filter((filter) => filter.value);

    setAdvancedFilters(updatedFilters);
  };

  const handleStartCall = (appointmentId: string): void => {
    if (!appointmentId) {
      console.error('Appointment ID not found');
      return;
    }
    handleRefresh();
    setIsModalOpen(false);
    navigate(`/Telehealth/${appointmentId}/meeting`);
  };

  const handleAddNew = (): void => {
    setIsModalOpen(true);
  };

  const handleFiltersClick = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <>
      <TelehealthAlert handleStartCall={handleStartCall} />
      <Paper radius={'12px'} className="telehealthContainerBorder">
        <Box className="telehealth-header" m="md" mb={0}>
          <Box className='title_box'>
            <Text className='telehealth_title'>Telehealth</Text>
            <Badge className="badge-style">{totalItems} records</Badge>
          </Box>

          <Group>
            <Tooltip label="Filter" position="top" withArrow>
              <ActionIcon size={33} className='button-icon' onClick={handleFiltersClick}>
                <IconFilter size={20} stroke={2.2} color='#667085'/>
              </ActionIcon>
            </Tooltip>

            <Button className={`addnew-button-default button-default btn_bg`} onClick={handleAddNew}>
              <IconVideo size={20} style={{ marginRight: '4px' }} />
              New
            </Button>
          </Group>
        </Box>
        <div className="telehealth-table-container"></div>
        {isFilterOpen && (
          <TelehealthFilters
            onApplyFilters={handleFilters}
            onCloseFilters={(isFilterOpen) => {
              setIsFilterOpen(isFilterOpen);
            }}
          />
        )}
        <Box m={0} p={0} className="telehealth-list">
          <Tabs mt="md" variant="pills" value={activeTab} className="tabs" onChange={(value) => {
            setActiveTab(value ?? 'all');
          }}>
            <Tabs.List w="min-content" mt={'0px'} ml={'10px'} className="tabs-list">
              {TELEHEALTHTABS?.map((tab: { label: string, value: string, }) => (
                <Tabs.Tab key={tab.value} value={tab.value} className="tabs-inline-style">
                  {tab.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {TELEHEALTHTABS.map((tab: { label: string, value: string, }) => (
              <Tabs.Panel key={tab.value} value={tab.value} pt="md" >
                <TelehealthTable
                  data={data}
                  isLoading={isLoading}
                  statuses={statuses}
                  handleStartCall={(value) => handleStartCall(value)}
                />
              </Tabs.Panel>
            ))}
          </Tabs>

          <CustomPagination
            total={Math.ceil(totalItems / itemsPerPage)}
            onChange={handlePageChange}
            value={Math.floor(offset / itemsPerPage) + 1}
          />
        </Box>

        <TelehealthDrawer
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onStartCall={(value) => handleStartCall(value)}
        />
      </Paper>
    </>
  )
}

export default Telehealth

import { ActionIcon, Box, Group, LoadingOverlay, Paper, Progress, Text, Textarea, Tooltip } from '@mantine/core';
import './Components.css';
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { IconCheck, IconDownload, IconPencil, IconReload, IconTrash } from '@tabler/icons-react';
import { Patient } from '@medplum/fhirtypes';
import { useParams } from 'react-router-dom';
import { UpdateClinicalNotePayload } from '../../../utils/interface';
import { exportClinicalNote, updateRegenerateClinicalNote } from '../../../utils/CustomAPI';
interface ClinicalNotesProps {
  clinicalNotes?: string;
  documentId?: string;
  patient: Patient;
  isEndingSession: boolean;
  setDocumentId: (documentId: string) => void;
  transcription: string;
}

const ClinicalNotes = (props: ClinicalNotesProps): JSX.Element => {
  const { documentId, transcription, isEndingSession, setDocumentId } = props;
  const [clinicalNote, setClinicalNote] = useState<string | undefined>('No notes available, please generate notes');
  const [isEditTextarea, setIsEditTextarea] = useState<boolean>(false);
  const medplum = useMedplum();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const patientName = `${props.patient?.name?.[0]?.given?.join(' ') ?? ''} ${
    props.patient?.name?.[0]?.family ?? ''
  }`.trim();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { id: appointmentId } = useParams<{ id: string }>();
  // Handle textarea change event
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setClinicalNote(event.currentTarget.value);
  };

  // Update clinical note when props change
  useEffect(() => {
    if (props.clinicalNotes && props.clinicalNotes.length > 0) {
      setProgress(100);
      setClinicalNote(props.clinicalNotes);
    }
  }, [props.clinicalNotes]);

  const progressAnimation = (): void => {
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(interval);
          return 90;
        }
        return prevProgress + 10;
      });
    }, 300);
  };

  useEffect(() => {
    if (progress < 100 && isEndingSession) {
      progressAnimation();
    }
  }, [isEndingSession]);

  // Update clinical notes in the backend
  const updateNotes = async (): Promise<void> => {
    if (documentId && clinicalNote && !isUpdating) {
      progressAnimation();
      try {
        const payload: UpdateClinicalNotePayload = {
          documentId: documentId,
          clinicalNote: clinicalNote,
        };
        setIsUpdating(true);
        await updateRegenerateClinicalNote(medplum, payload).then((response) => {
          setClinicalNote(response.clinicalNote);
          setDocumentId(response?.documentId);
          setIsEditTextarea(false);
        });
        setIsUpdating(false);
        setProgress(100);
        showNotification({ color: 'green', message: 'Clinical Note updated successfully' });
      } catch (error) {
        console.log(error);
        setIsUpdating(false);
        setProgress(100);
        showNotification({ color: 'red', message: 'Failed to update clinical note' });
      }
    }
  };

  const downloadPdf = async (documentId: string, patientName: string): Promise<void> => {
    setIsDownloading(true);
    await exportClinicalNote(medplum, documentId)
      .then((response: any) => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          showNotification({ color: 'green', message: 'Exported successfully' });
        }
        setIsDownloading(false);
      })
      .catch((error: Error) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
        setIsDownloading(false);
      });
  };

  //Handle Regenerate clinical note
  const handleRegenerate = async (): Promise<void> => {
    try {
      progressAnimation();
      setIsRegenerating(true);
      if (transcription) {
        const payload: UpdateClinicalNotePayload = {
          documentId: documentId,
          transcript: transcription,
          appointmentId: appointmentId ?? '',
        };
        await updateRegenerateClinicalNote(medplum, payload).then((response: { clinicalNote: React.SetStateAction<string | undefined>; documentId: string }) => {
          setClinicalNote(response.clinicalNote);
          setDocumentId(response?.documentId);
        });
        showNotification({ color: 'green', message: 'Clinical Note generated successfully' });
      } else {
        setClinicalNote('No transcription available to generate notes');
        showNotification({ color: 'red', message: 'No transcription available to generate notes' });
      }
      setIsRegenerating(false);
      setProgress(100);
    } catch (error) {
      console.error(error);
      showNotification({ color: 'red', message: 'Failed to generate clinical note' });
      setIsRegenerating(false);
      setProgress(100);
    }
  };

  // Handle delete clinical note
  const handleDelete = async (): Promise<void> => {
    if (documentId && !isUpdating && setDocumentId) {
      try {
        setIsDeleting(true);
        await medplum.deleteResource('DocumentReference', documentId);
        setIsDeleting(false);
        showNotification({ color: 'green', message: 'Clinical Note deleted successfully' });
        setClinicalNote('No notes available, please generate notes');
        setDocumentId('');
      } catch (error) {
        console.log(error);
        setIsDeleting(false);
        showNotification({ color: 'red', message: 'Failed to delete clinical note' });
      }
    }
  };

  return (
    <Paper h={'100%'}>
      <Group justify='end'>
        {isEndingSession || isUpdating || isRegenerating ? (
          <Box display={progress >= 100 ? 'none' : 'block'} w={'100%'} my={6}>
            <Text mb={5}>
              Generating Clinical Notes...
            </Text>
            <Progress color="#2AAAAF" radius="lg" size="sm" value={progress} />
          </Box>
        ) : (
          <Box className="notes-actions-container">
            <>
              {isEditTextarea ? (
                <Tooltip label="Save">
                  <ActionIcon onClick={updateNotes} loading={isUpdating} size={33} className="button-icon">
                    <IconCheck size={20} color="#00B14A" stroke={2.2} />
                  </ActionIcon>
                </Tooltip>
              ) : (
                <Tooltip label="Edit">
                  <ActionIcon
                    disabled={isUpdating || isRegenerating || !documentId}
                    size={33}
                    className="button-icon"
                    onClick={() => setIsEditTextarea(true)}
                  >
                    <IconPencil size={20} stroke={2.2} color="#667085" />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
            <Tooltip label="Delete">
              <ActionIcon
                disabled={isUpdating || isRegenerating || !documentId}
                loading={isDeleting}
                size={33}
                className="button-icon"
                onClick={handleDelete}
                ml={12}
              >
                <IconTrash size={20} stroke={2.2} color="red" />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Regenerate">
              <ActionIcon
                disabled={isUpdating || isRegenerating}
                size={33}
                className="button-icon"
                onClick={handleRegenerate}
                ml={12}
              >
                <IconReload size={20} stroke={2.2} color="#667085" />
              </ActionIcon>
            </Tooltip>

           <Tooltip label="Download">
              <ActionIcon
                loading={isDownloading}
                disabled={isUpdating || isRegenerating || !documentId}
                size={33}
                className="button-icon"
                onClick={() => downloadPdf(documentId ?? '', patientName)}
                ml={12}
              >
                <IconDownload size={20} stroke={2.2} color="#667085" />
              </ActionIcon>
            </Tooltip>
          </Box>
        )}

        <Box pos="relative" className="clinical-notes-box" w={'100%'}>
          {(isEndingSession || isRegenerating) && <LoadingOverlay h={'100%'} visible={true} color="#9552e8" />}

          {!isEditTextarea ? (
            clinicalNote?.split('\n').map((paragraph: string, index: number, array: string[]) => (
              <React.Fragment key={`${paragraph}-${index}`}>
                <Text
                  className="note-text"
                  span
                  dangerouslySetInnerHTML={{
                    __html: paragraph.replace(/(\w+):/g, '<span class="note-label">$1</span>:'),
                  }}
                />
                {index !== array.length - 1 && paragraph.trim() && <br />}
              </React.Fragment>
            ))
          ) : (
            <Box pos="relative">
              {isUpdating && <LoadingOverlay visible={true} h={'100%'} color="#9552e8" />}

              <Textarea
                //minRows={19}
                disabled={isUpdating}
                styles={{ input: { border: 'none' } }}
                onChange={handleTextareaChange}
                value={clinicalNote}
                autosize
              >
                {clinicalNote}
              </Textarea>
            </Box>
          )}
        </Box>
      </Group>
    </Paper>
  );
};

export default ClinicalNotes;

import { useState } from 'react';
import { Button, Dialog, Group, Text } from '@mantine/core';
interface IsoRecordingModalProps {
  onClick: () => void;
  onCancel: () => void;
}

const IsoRecordingModal = (props: IsoRecordingModalProps) => {
  const [visible, setVisible] = useState(true);
  const { onClick, onCancel } = props;
  return (
    <Dialog className="recording-iso-ask-dialog" opened={visible} withCloseButton onClose={close} size="lg" radius="md">
      <Text size="lg" fw={500} mt="xl">
        ISO Recording Asking
      </Text>
      <Text size="sm" mb="xs" fw={500}>
        Do you want you allow Individual Cloud recording mode?
      </Text>

      <Group align="flex-end">

        <Button color='red' onClick={async () => {
          await onCancel();
          setVisible(false);
        }}>Reject
        </Button>
        <Button color='green' onClick={async () => {
          await onClick();
          setVisible(false);
        }}>Accept
        </Button>
      </Group>
    </Dialog>
  );
};

export default IsoRecordingModal;

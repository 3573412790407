import { useContext } from 'react';
import ZoomMediaContext from '../../context/media-context';
import { MediaDevice } from '../video-types';
import { Button, Menu, Tooltip } from '@mantine/core';
import { IconCheck, IconVideo, IconVideoOff } from '@tabler/icons-react';

interface CameraButtonProps {
  isStartedVideo: boolean;
  isMirrored?: boolean;
  isBlur?: boolean;
  isPreview?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  onMirrorVideo?: () => void;
  onVideoStatistic?: () => void;
  onBlurBackground?: () => void;
  onSelectVideoPlayback?: (url: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
  activePlaybackUrl?: string;
}

const videoPlaybacks = [
  { title: 'ZOOM ZWA', url: 'https://source.zoom.us/brand/mp4/Using%20the%20Zoom%20PWA.mp4' },
  { title: 'ZOOM Cares', url: 'https://source.zoom.us/brand/mp4/Zoom%20Cares%20Nonprofit%20Impact.mp4' },
  {
    title: 'ZOOM One',
    url: 'https://source.zoom.us/brand/mp4/Zoom%20One%20-%20Team%20Chat%2C%20Phone%2C%20Email%2C%20and%20more.mp4'
  }
];

const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    isMirrored,
    isBlur,
    isPreview,
    activePlaybackUrl,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onVideoStatistic,
    onBlurBackground,
    onSelectVideoPlayback
  } = props;

  const { mediaStream } = useContext(ZoomMediaContext);

  const onMenuItemClick = (key: string) => {
    if (key === 'mirror') {
      onMirrorVideo?.();
    } else if (key === 'statistic') {
      onVideoStatistic?.();
    } else if (key === 'blur') {
      onBlurBackground?.();
    } else if (/^https:\/\//.test(key)) {
      onSelectVideoPlayback?.(key);
    } else {
      onSwitchCamera(key);
    }
  };

  const menuItems =
    cameraList &&
    cameraList.length > 0 && (
      <>
        <Menu.Label>Select a Camera</Menu.Label>
        {cameraList.map((item) => (
          <Menu.Item
            key={item.deviceId}
            leftSection={item.deviceId === activeCamera ? <IconCheck size={14} color='#868686' stroke={1.5}/> : null}
            onClick={() => onMenuItemClick(item.deviceId)}
          >
            {item.label}
          </Menu.Item>
        ))}
        {!isPreview && (
          <>
            <Menu.Divider />
            <Menu.Label>Select a Video Playback</Menu.Label>
            {videoPlaybacks.map((item) => (
              <Menu.Item
                key={item.url}
                leftSection={item.url === activePlaybackUrl ? <IconCheck size={14} color='#868686' stroke={1.5}/> : null}
                onClick={() => onMenuItemClick(item.url)}
              >
                {item.title}
              </Menu.Item>
            ))}
          </>
        )}
        <Menu.Divider />
        {!isPreview && (
          <Menu.Item
            onClick={() => onMenuItemClick('mirror')}
            leftSection={isMirrored ? <IconCheck size={14} color='#868686' stroke={1.5}/> : null}
          >
            Mirror My Video
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => onMenuItemClick('blur')}
          leftSection={isBlur ? <IconCheck size={14} color='#868686' stroke={1.5}/> : null}
        >
          {mediaStream?.isSupportVirtualBackground() ? 'Blur My Background' : 'Mask My Background'}
        </Menu.Item>
        {!isPreview && (
          <>
            <Menu.Divider />
            <Menu.Item onClick={() => onMenuItemClick('statistic')}>Video Statistic</Menu.Item>
          </>
        )}
      </>
    );

  return (
    <div className={className}>
      {isStartedVideo && menuItems ? (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              className="vc-button"
              size="sm"
              onClick={onCameraClick}
              variant="filled"
              radius="xl"
            >
              <IconVideo color='#868686' stroke={1.5}/>
            </Button>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      ) : (
        <Tooltip label={`${isStartedVideo ? 'Stop camera' : 'Start camera'}`} position="top">
          <Button
            className="vc-button"
            onClick={onCameraClick}
            size="sm"
            variant="filled"
            color="blue"
            radius="xl"
          > {isStartedVideo ? <IconVideo color='#868686' stroke={1.5}/> : <IconVideoOff color='#868686' stroke={1.5}/>} </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default CameraButton;

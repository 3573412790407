import { useDisclosure } from '@mantine/hooks';
import { Alert, Box, Divider, Group, Text } from '@mantine/core';
import './Telehealth.css';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { getTelehealthAlerts } from '../../utils/CustomAPI';

interface TelehealthAlertProps {
  handleStartCall: (appointmentId: string) => void;
}

interface PatientAppointment {
  time: string;
  name: string;
  appointmentId?: string;
  patientId?: string;
}
const TelehealthAlert = ({ handleStartCall }: TelehealthAlertProps): JSX.Element => {
  const [opened, { close }] = useDisclosure(true);
  const medplum = useMedplum();
  const [upcomingAppointments, setUpcomingAppointments] = useState<PatientAppointment[]>([]);
  const [waitingListPatients, setWaitingListPatients] = useState<PatientAppointment[]>([]);
  const [showAllUpcoming, setShowAllUpcoming] = useState<boolean>(false);
  const [showAllWaiting, setShowAllWaiting] = useState<boolean>(false);

  useEffect(() => {
    // Set initial timeout and interval for fetching data
    const initialTimeout = setTimeout(() => {
      fetchData().catch(console.error);
      const interval = setInterval(() => {
        fetchData().catch(console.error);
      }, 300000);
      return () => clearInterval(interval);
    }, 5000);

    return () => clearTimeout(initialTimeout);
  }, []);

  if (!opened) {
    return <></>;
  }

  const parseTime = (timeString: string): Date => {
    // Parse time string into a Date object
    const currentDate = new Date();
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);

    // Adjust hours for 12-hour format
    let adjustedHours = hours;
    if (period) {
      if (period.toLowerCase() === 'pm' && hours !== 12) {
        adjustedHours += 12;
      } else if (period.toLowerCase() === 'am' && hours === 12) {
        adjustedHours = 0;
      }
    }

    currentDate.setHours(adjustedHours, minutes, 0, 0);
    return currentDate;
  };

  const sortAppointmentsByProximity = (appointments: PatientAppointment[]): PatientAppointment[] => {
    // Sort appointments by their proximity to the current time
    const now = new Date();
    return appointments.sort((a, b) => {
      const timeA = parseTime(a.time);
      const timeB = parseTime(b.time);

      // Calculate time difference from now
      const diffA = Math.abs(timeA.getTime() - now.getTime());
      const diffB = Math.abs(timeB.getTime() - now.getTime());

      return diffA - diffB;
    });
  };

  const fetchData = async (): Promise<void> => {
    // Fetch telehealth alerts data
    try {
      const response = await getTelehealthAlerts(medplum);

      // Sort and slice upcoming appointments
      const sortedUpcoming = sortAppointmentsByProximity(response.upcoming)
        .slice(0, 4)
        .map((appointment: PatientAppointment) => ({
          time: appointment.time,
          name: appointment.name,
          appointmentId: appointment.appointmentId,
          patientId: appointment.patientId,
        }));

      // Sort and slice waiting list patients
      const sortedWaiting = sortAppointmentsByProximity(response.waitingList)
        .slice(0, 2)
        .map((appointment: PatientAppointment) => ({
          time: appointment.time,
          name: appointment.name,
          appointmentId: appointment.appointmentId,
          patientId: appointment.patientId,
        }));

      setUpcomingAppointments(sortedUpcoming);
      setWaitingListPatients(sortedWaiting);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleShowAllUpcoming = (): void => {
    // Toggle the display of all upcoming appointments
    setShowAllUpcoming(!showAllUpcoming);
  };

  const toggleShowAllWaiting = (): void => {
    // Toggle the display of all waiting list patients
    setShowAllWaiting(!showAllWaiting);
  };

  return (
    <Box
      className="telehealth-alert"
      mt={10}
      display={upcomingAppointments.length > 0 || waitingListPatients.length > 0 ? 'block' : 'none'}
    >
      <Alert
        onClose={close}
        bg={'#eaf6f745'}
        icon={<img src="../../img/icons/video-recorder.svg" />}
        withCloseButton
        display={upcomingAppointments.length > 0 ? 'block' : 'none'}
      >
        <Group align="center" className="alert-content">
          <Box className="message-container" w={'100%'}>
            <Box>
              <Box className="upcoming-appointments-header">
                <Text fw={600} c={'#344054'}>
                  Upcoming Telehealth Consultations{' '}
                </Text>
                <Text className="patient-title-txt"> (Next 60 minutes)</Text>
                <Text span className="patient-count-box">
                {upcomingAppointments.length} {upcomingAppointments.length > 1 ? 'Appointments' : 'Appointment'}
                </Text>
              </Box>
              <div className="upcoming-appointments-list">
                {upcomingAppointments
                  .slice(0, showAllUpcoming ? upcomingAppointments.length : 1)
                  .map((appointment, index) => (
                    <Text span key={index} className="patient-title tw-flex">
                      <Text span className="patient-title">
                        {appointment.time} :
                      </Text>

                      <Text span className="doctor-name-txt">
                        <Text
                          span
                          onClick={() => window.open(`/Patient/${appointment.patientId}/details`, '_blank')}
                          c="#2AAAAF"
                        >
                          {' '}
                          {appointment.name}
                        </Text>
                        <img
                          src="../../img/video-recorder.svg"
                          className="video-icon"
                          onClick={() => {
                            if (appointment.appointmentId) {
                              handleStartCall(appointment.appointmentId);
                            }
                          }}
                          alt="Start Call"
                        />
                      </Text>
                    </Text>
                  ))}
                {upcomingAppointments.length > 1 && (
                  <Text span className="toggle-appointments toggle-button" onClick={toggleShowAllUpcoming}>
                    {showAllUpcoming ? 'Hide' : `+${upcomingAppointments.length - 1} more`}
                  </Text>
                )}
              </div>
            </Box>
          </Box>
        </Group>
      </Alert>

      <Divider
        my={'sm'}
        w={'100%'}
        display={upcomingAppointments.length > 0 && waitingListPatients.length > 0 ? 'block' : 'none'}
      />
      <Alert
        variant="light"
        onClose={close}
        bg={'transparent'}
        icon={<img src="../../img/icons/ic-waiting.svg" alt="" />}
        withCloseButton={waitingListPatients.length > 0 && upcomingAppointments.length === 0}
        display={waitingListPatients.length > 0 ? 'block' : 'none'}
      >
        <Group align="center" className="alert-content">
          <Box className="message-container" w={'100%'}>
            <Box>
              <Box className="upcoming-appointments-header">
                Waiting list Patients{' '}
                <Text className="patient-count-box">
                  {waitingListPatients.length} {waitingListPatients.length > 1 ? 'Appointments' : 'Appointment'}
                </Text>
              </Box>
              <span className="waiting-list">
                {waitingListPatients.slice(0, showAllWaiting ? waitingListPatients.length : 1).map((patient, index) => (
                  <Text
                    span
                    key={index}
                    className="patient-title"
                    onClick={() => {
                      if (patient.appointmentId) {
                        handleStartCall(patient.appointmentId);
                      }
                    }}
                  >
                    <Text span className="doctor-name-txt">
                      {patient.name}:
                    </Text>{' '}
                    <Text span className="waiting-txt">
                      Waiting since : {patient.time}
                    </Text>
                  </Text>
                ))}
                {waitingListPatients.length > 1 && (
                  <Text span className="toggle-appointments toggle-button" onClick={toggleShowAllWaiting}>
                    {showAllWaiting ? 'Hide' : `+${waitingListPatients.length - 1} more`}
                  </Text>
                )}
              </span>
            </Box>
            <Group className="button-group" mt={16}>
              <span onClick={close} className="dismiss-button">
                Dismiss
              </span>
            </Group>
          </Box>
        </Group>
      </Alert>
    </Box>
  );
};

export default TelehealthAlert;

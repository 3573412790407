/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface AppContextProps {
  transcript: string;
  setTranscript: (value: any) => void;
  isSessionOn: boolean;
  setIsSessionOn: (value: boolean) => void;
  setIsConfirmOpen: (value: boolean) => void;
  isConfirmOpen: boolean;
  setRedirectionUrl: (value: string) => void;
  redirectionUrl: string;
  setHostParticipants: (value: {isHost: boolean, hostName: string}) => void;
  hostParticipants: { isHost: boolean; hostName: string };
  setJoinUser: (value: string) => void;
  joinUser: string;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [transcript, setTranscript] = useState('');
  const [isSessionOn, setIsSessionOn] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState('');
  const [hostParticipants, setHostParticipants] = useState<{isHost: boolean, hostName: string}>({
    isHost: false,
    hostName: '',
  });
  const [joinUser, setJoinUser] = useState('');
  return (
    <AppContext.Provider
      value={React.useMemo(
        () => ({ transcript, setTranscript, isSessionOn, setIsSessionOn, isConfirmOpen, setIsConfirmOpen, redirectionUrl, setRedirectionUrl, hostParticipants, setHostParticipants, joinUser, setJoinUser }),
        [transcript, setTranscript, isSessionOn, setIsSessionOn, isConfirmOpen, setIsConfirmOpen, redirectionUrl, setRedirectionUrl, hostParticipants, setHostParticipants, joinUser, setJoinUser]
      )}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};